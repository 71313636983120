import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Profile from "../components/profile"
import Pictures from "../components/pictures"
import Portfolio from "../components/portfolio"
import Contact from "../components/contact"
import Footer from "../components/footer"
import { PopupWidget } from "react-calendly"

export default function Home() {
  const [canRender, setCanRender] = useState(false)

  useEffect(() => setCanRender(true), [])

  return (
    <Layout>
      {canRender ? (
        <PopupWidget
          url="https://calendly.com/mariokehl/30min"
          rootElement={document.getElementById("___gatsby")}
          text="Kostenloses Erstgespräch vereinbaren"
          textColor="#ffffff"
          color="#4ea8de"
        />
      ) : null}
      <Intro />
      <Profile />
      <Pictures />
      <Portfolio />
      <Contact />
      <Footer />
    </Layout>
  )
}

import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Logo from "../assets/images/svg/logo_notext.svg"
import { Helmet } from "react-helmet-async"

export default function Imprint() {
  return (
    <Layout>
      <Helmet>
        <title>Impressum – Mario Kehl IT GmbH</title>
        <meta name="description" content="" />
        <meta name="keywords" content="Impressum, Mario Kehl" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="layout-container">
        <Link id="back-to-home" to="/">
          <Logo />
        </Link>

        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Mario Kehl IT GmbH
          <br />
          Riedelstra&szlig;e 32
          <br />
          34130 Kassel
        </p>
        <p>
          Handelsregister: HRB 19674
          <br />
          Registergericht: Amtsgericht Kassel
        </p>
        <p>
          <strong class="h3">Vertreten durch:</strong>
          <br />
          Mario Kehl
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 561 49972560
          <br />
          E-Mail: bureau@mariokehl.com
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE366392891
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p>
          Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
        </p>
      </div>
    </Layout>
  )
}
